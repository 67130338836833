<template>
  <b-modal
    id="activityModalData"
    :title="modalTitle"
    size="lg"
    hide-footer
  >
    <b-card>
      <b-row>
        <b-col class="text-right">
          <feather-icon
            icon="FileTextIcon"
            class="text-primary font-medium-4 cursor-pointer mr-3 mb-1"
            v-b-tooltip.hover.v-primary
            title="Export"
            @click="exportToExcel()"
          />
        </b-col>
      </b-row>
      <b-table-simple
        hover
        caption-top
        responsive
        class="rounded-bottom mb-0 table table-bordered"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Vendor Name</b-th>
            <b-th>Vendor Code</b-th>
            <b-th>Lead Code</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in activityReportData"
            :key="index"
          >
            <b-td class="text-left">
              {{ item.vendor_name }}
            </b-td>
            <b-td>
              {{ item.vendor_code }}
            </b-td>
            <b-td>
              <a
                v-if="item.record_type == 4"
                :href="'/opportunity/' + item.record_id"
                target="_blank"
                rel="noopener noreferrer"
                class="text-info font-weight-bold"
              >
                {{ item.lead_code }}
              </a>
              <a
                v-else
                :href="'/lead/' + item.record_id"
                target="_blank"
                rel="noopener noreferrer"
                class="text-info font-weight-bold"
              >
                {{ item.lead_code }}
              </a>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </b-modal>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import excel from '@/excel'

export default {
  components: {
  },
  props: ['activityReportData'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      openModal: false,
      modalTitle: 'Activity Detail Report',
      modalData: [],
      excelData: [],
    }
  },
  watch: {
    activityReportData: {
      handler(val) {
        this.modalData = val
        this.openModal = true
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', componentId => {
      if (componentId === 'activityModalData') {
        this.openModal = false
      }
    })
  },
  methods: {
    exportToExcel() {
      this.excelData = []
      this.modalData.forEach(element => {
        this.excelData.push({
          'Vendor Name': element.vendor_name,
          'Vendor Code': element.vendor_code,
          'Lead Code': element.lead_code,
        })
      })
      excel.methods.exportToExcelJson(this.excelData, 'Activity Detail')
    },
  },
}
</script>
