<template>
  <div>
    <div class="d-flex justify-content-between mb-1">

      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        variant="outline-primary"
        @click="exportToExcel"
      >
        {{ t('Export') }}
      </b-button>

    </div>

    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <activity-report-filter
        :filterparams="form"
        @searchData="GetSearchFilter"
      />
    </b-collapse>
    <b-card>
      <b-table-simple
        hover
        caption-top
        responsive
        class="rounded-bottom mb-0 table table-bordered"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th
              class="text-left"
            >
              Name
            </b-th>
            <b-th
              class="text-center"
            >
              Visit
            </b-th>
            <b-th
              class="text-center"
            >
              Call
            </b-th>
            <b-th
              class="text-center"
            >
              Total
            </b-th>
            <b-th
              class="text-center"
            >
              Daily Avg.
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in ActivityReport"
            :key="index"
          >
            <b-td
              class="text-left"
            >
              {{ item.name }}
            </b-td>
            <b-td
              class="text-center text-info font-weight-bold"
              @click="openModal(item, '2002')"
            >
              {{ item.visit }}
            </b-td>
            <b-td
              class="text-center text-info font-weight-bold"
              @click="openModal(item, '2001')"
            >
              {{ item.call }}
            </b-td>
            <b-td
              class="text-center"
            >
              {{ item.total }}
            </b-td>
            <b-td
              class="text-center"
            >
              {{ item.daily_avg }}
            </b-td>
          </b-tr>
          <b-tr
            v-if="ActivityReport.length == 0"
          >
            <b-td
              class="text-center"
              colspan="5"
            >
              Record Not Found.
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <activity-modal-report
      :activity-report-data="activityReportData"
      :activity-modal-data="activityModalData"
    />
  </div>
</template>
<script>
import {
  BButton, BCard, BCollapse, VBToggle,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'
import ActivityReportFilter from './ActivityReportFilter.vue'
import { showLoader, hideLoader } from '@/utils/common'
import excel from '@/excel'
import ActivityModalReport from './ActivityModalReport.vue'

export default {
  components: {
    BButton,
    BCard,
    BCollapse,
    ActivityReportFilter,
    ActivityModalReport,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      constants,
      form: {
        user: '',
        start_date: null,
        end_date: null,
        period: '',
      },
      ActivityReport: [],
      activityModalData: [],
      activityReportData: [],
      excelData: [],
      type: '',
    }
  },
  mounted() {

  },
  methods: {
    exportToExcel() {
      this.ActivityReport.forEach(el => {
        this.excelData.push({
          'Name ': el.name,
          'Visit ': el.visit,
          'Call ': el.call,
          'Total ': el.total,
          'Daily Average': el.daily_avg,
        })
      })
      excel.methods.exportToExcelJson(this.excelData, 'Activity Report')
    },
    GetSearchFilter(val) {
      this.form = val
      this.getActivityReport()
    },
    async getActivityReport() {
      showLoader()
      await store.dispatch('ReportStore/GetActivityReport', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.ActivityReport = response.data.data
          document.getElementById('loading-bg').classList.remove('loading-bg')
          // eslint-disable-next-line eqeqeq
        } else if (response.data.code == constants.ERROR_500) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          document.getElementById('loading-bg').classList.remove('loading-bg')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
    openModal(event, type) {
      this.type = type
      this.activityReportData = []
      showLoader()
      this.$bvModal.show('activityModalData')
      store.dispatch('DashboardApi/GetFunnelLeadData',
        {
          type: this.type,
          user: event.companyuserid,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.activityReportData = response.data.data
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
